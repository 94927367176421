import React, { useState } from "react";
import { getVisibleImages } from "./helper";
import { ImageList, ImageListItem } from "@mui/material";
import RenderThumnailModal from "./RenderThumnailModal";
import { getCurrentClass } from "../../../CardFrameCollection/utils";
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import classNames from "classnames";

const MasonryGrid = (props) => {
  const { listPresentation, cardFrame, containerTracks, oneGridCellGeometry } = props;
  const [showThumbnailLayout, setShowThumbnailLayout] = useState({
    isVisible: false,
    currIndex: 3,
  })
  const imagesList = getVisibleImages(cardFrame?.imagesData);

  function handleImageClick(index: number) {
    setShowThumbnailLayout(prev => {
      return {
        currIndex: index,
        isVisible: !prev.isVisible
      }
    })
  }

  return (
    <div className="tw-h-full tw-w-full tw-px-[16px]">
      <ImageList variant="masonry"  cols={containerTracks?.numColumns || 4}  gap={listPresentation?.rowColumnGap}>
        {imagesList.map((imageData, index) => (
          <ImageListItem key={imageData.imageUrl + index} className="tw-overflow-hidden">
            <img
              onClick={() => handleImageClick(index)}
              srcSet={imageData.imageUrl ? `${imageData.imageUrl}?w=${oneGridCellGeometry.width}&fit=crop&auto=format&dpr=2 2x`: '/assets/images/richTextWithImage.png'}
              src={imageData.imageUrl ? `${imageData.imageUrl}?w=${oneGridCellGeometry.width}&fit=crop&auto=format` :  '/assets/images/richTextWithImage.png'}
              className={classNames(contentAnimationStyle[getCurrentClass(cardFrame?.hoverEffect)], 'tw-cursor-pointer')}
              loading="lazy"
              height={oneGridCellGeometry?.height}
              style={{maxHeight: 1.25 * oneGridCellGeometry?.height}}
            />
          </ImageListItem>
        ))}
      </ImageList>
      {showThumbnailLayout.isVisible && <RenderThumnailModal cardFrame={cardFrame} config={showThumbnailLayout} setConfig={setShowThumbnailLayout} />}
    </div>
  )
};

export default MasonryGrid;
