import { useEffect, useMemo, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { ADMIN_WIDGETS } from 'src/constants/widgets';
import { useSSRSelector } from 'src/redux';
import {
  fetchCatalogItemsByCategoryId,
  fetchCollectionCatalogItemById,
} from 'src/redux/actions';
import { CATEGORY_TYPE } from 'src/utils/constants';

// This adds a collection in the list if a new collection is added by the merchant from web console
export function useGetIterableItems({
  // old list config set from builder
  builderCollections,
  removeNotVisibleItems = false,
  defaultItemVisibility = true,
  fromBuilderOutline = false,
  contentType = 'collection',
  dependency = [],
}) {
  const dispatch = useDispatch();
  const [moreItemsAvailable, setMoreItemsAvailable] = useState(false);
  const listType = {
    tag: contentType?.toLowerCase()?.includes('tag'),
    collection: contentType?.toLowerCase()?.includes('collection'),
    category: contentType?.toLowerCase()?.includes('category'),
    featuredTag: contentType === 'featuredTag',
    featuredCollection: contentType === 'featuredCollection',
    featuredCategory: contentType === ADMIN_WIDGETS.FEATURED_CATEGORIES.type,
  };

  const {
    latestCollections,
    storeData,
    latestCollectionProducts,
    latestCategories,
    latestTags,
    latestCategoryProducts,
  } = useSSRSelector((state) => ({
    latestCollectionProducts: state.catalogReducer.collectionCatalogItems,
    storeData: state.storeReducer.store,
    latestCategories: state.catalogReducer.categories,
    latestTags: state.catalogReducer.productTags,
    // Featured collection products
    latestCollections: state.catalogReducer.collections,
    // Featured tags/category products
    latestCategoryProducts: state.catalogReducer.catalog_items,
  }));
  const [currentChosenCollection, setCurrentChosenCollection] = useState(null);

  const selectedLatestList = (() => {
    if (!contentType) return null;
    if (listType.tag) return latestTags;
    if (listType.collection) return latestCollections;
    return latestCategories;
  })();

  useEffect(() => {
    if (!fromBuilderOutline && Array.isArray(builderCollections)) {
      if (
        listType.featuredCollection ||
        listType.featuredTag ||
        listType.featuredCategory
      ) {
        const shownItem = builderCollections?.find((item) => item?.isVisible);
        if (!shownItem) {
          return;
        }
        const list = listType.featuredCollection
          ? latestCollections
          : listType.featuredCategory
            ? latestCategories
            : latestTags;

        const storeCollection = list?.find((listItem) => listItem.id === shownItem.id);

        setCurrentChosenCollection(storeCollection);
        // Don't make API calls if data already present in reducer
        const isProductListAlreadyPresent = (() => {
          if (listType.featuredCollection) {
            return latestCollectionProducts?.[storeCollection?.id];
          }
          return latestCategoryProducts?.[storeCollection?.id];
        })();
        if (!storeCollection || (storeCollection && isProductListAlreadyPresent)) {
          return;
        }
        const dispatchCalls = [];
        if (listType.featuredCollection) {
          storeCollection?.categories?.forEach((category) => {
            dispatchCalls.push(
              fetchCollectionCatalogItemById({
                storeId: storeData.store_id,
                collectionId: storeCollection.id,
                categoryId: category.id,
              })
            );
          });
          batch(() => {
            dispatchCalls.map((apiCall) => dispatch(apiCall));
          });
        }
        if (listType.featuredTag) {
          const payload = {
            store_id: storeData.store_id,
            category_id: storeCollection.id,
            category_type: CATEGORY_TYPE.TAG,
            page_no: 0,
          };
          dispatch(fetchCatalogItemsByCategoryId(payload));
        }
        if (listType.featuredCategory) {
          const payload = {
            store_id: storeData.store_id,
            category_id: storeCollection.id,
            category_type: CATEGORY_TYPE.CATEGORY,
            page_no: 0,
          };
          dispatch(fetchCatalogItemsByCategoryId(payload));
        }
      }
    }
  }, [
    builderCollections,
    ...(listType.featuredCollection ? [latestCollections, latestCollectionProducts] : []),
    ...(listType.featuredTag ? [latestTags, latestCategoryProducts] : []),
    ...(listType.featuredCategory ? [latestCategories, latestCategoryProducts] : []),
  ]);

  const iterableList = useMemo(() => {
    const list = [];
    const newAddedItems = [];

    if (!(Array.isArray(builderCollections) || selectedLatestList?.length)) {
      return list;
    }

    // For CFE: For product items in a collection/tag/category
    if (
      !fromBuilderOutline &&
      (listType.featuredCollection || listType.featuredTag || listType.featuredCategory)
    ) {
      const productList = listType.featuredCollection
        ? latestCollectionProducts
        : latestCategoryProducts;
      if (productList?.[currentChosenCollection?.id]) {
        const interList = [];
        const items: any[] = (() => {
          if (Array.isArray(productList[currentChosenCollection.id]))
            return productList[currentChosenCollection.id];
          return Object.values(productList[currentChosenCollection.id]);
        })();
        // Fill interlist with products to be pushed
        for (const item of items) {
          if (interList?.length >= 13) {
            !moreItemsAvailable && setMoreItemsAvailable(true);
            break;
          } else
            !Array.isArray(item) ? interList.push(item) : interList.push(...(item || []));
        }
        if (interList?.length) {
          list.push(
            ...interList.map((productItem: any) => ({
              data: productItem,
              isVisible: true,
              id: productItem?.id,
              type: 'product',
            }))
          );
        }
      }
    } else {
      // For collection, categories, tags list
      selectedLatestList.forEach((latestListItem) => {
        const builderRefItemIndex = builderCollections?.findIndex(
          (builderItem) => builderItem?.id === latestListItem.id
        );
        const builderItem = builderCollections?.[builderRefItemIndex] || {};
        const isLatestListItemAlreadyPresent = builderRefItemIndex > -1;
        // Is latest item from backend present in build list, update the data
        if (isLatestListItemAlreadyPresent) {
          list[builderRefItemIndex] = {
            ...builderItem,
            itemCount: latestListItem?.categories?.length,
            data: latestListItem,
            type: contentType,
          };
        }
        // if not then add it in list
        else {
          newAddedItems.push({
            ...builderItem,
            id: latestListItem.id,
            rank: latestListItem.rank || 0,
            isVisible: defaultItemVisibility,
            itemCount: latestListItem?.categories?.length,
            data: latestListItem,
            type: contentType,
          });
        }
      });
    }
    let finalList = list?.concat(newAddedItems);
    if (removeNotVisibleItems) {
      finalList = finalList = list?.filter((item) => item && item.isVisible);
    }
    return finalList;
  }, [
    ...dependency,
    selectedLatestList,
    ...(listType.featuredCollection
      ? [latestCollectionProducts, currentChosenCollection]
      : []),
    ...(listType.featuredTag || listType.featuredCategory
      ? [currentChosenCollection, latestCategoryProducts]
      : []),
  ]);

  return { iterableList, moreItemsAvailable };
}
