import { COMMON_LAYOUT_TYPE } from "../Testimonials/Constants";
import CarouselGridLayout from "./CarouselGridLayout";
import GalleryCollage1 from "./GalleryCollage1";
import GalleryCollage2 from "./GalleryCollage2";
import MasonryGrid from "./MansoryLayout";
import ThumbnailLayout from "./ThumbnailLayout";

function GalleryWidget(props) {
  const { listPresentation, cardFrame } = props
  function renderLayoutType() {
    switch (listPresentation.layoutName) {
      case COMMON_LAYOUT_TYPE.ONE:
      case COMMON_LAYOUT_TYPE.TWO:
        return <CarouselGridLayout {...props} />
      case COMMON_LAYOUT_TYPE.THREE:
        return <ThumbnailLayout cardFrame={cardFrame} />
      case COMMON_LAYOUT_TYPE.FOUR:
        return <MasonryGrid {...props} />
      case COMMON_LAYOUT_TYPE.FIVE:
        return <GalleryCollage1 {...props} />
      case COMMON_LAYOUT_TYPE.SIX:
        return <GalleryCollage2 {...props} />
    }
  }
  return (
    renderLayoutType()
  )
}

export default GalleryWidget;