import { useMemo, useState } from "react";
import { ImageList, ImageListItem } from "@mui/material";
import { getVisibleImages } from "./helper";
import RenderThumnailModal from "./RenderThumnailModal";
import { isMobileDevice } from "src/components/WidgetMaker/WidgetDnD/isMobileDevice";
import { getCurrentClass } from "../../../CardFrameCollection/utils";
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { useWidgetDndContextData } from "src/context/WidgetDndContext";

function GalleryCollage2(props) {
  const [showThumbnailLayout, setShowThumbnailLayout] = useState({
    isVisible: false,
    currIndex: 4,
  })
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const globalBorderRadius = globalStyle?.imageCards?.style?.borderRadius?.replace('!important', '');

  const { cardFrame, oneGridCellGeometry, gridGapAxis = {} } = props;
  const imagesList = getVisibleImages(cardFrame?.imagesData);
  const hiddenImageLength = imagesList?.length - 5;
  const isMobile = isMobileDevice();
  const heightAccToDevice = isMobile ? 150 : 300;
  const columnAccToDeivce = isMobile ? 2 : 4;

  function getSrcSet(url, rows, columns, index) {
    const { width } = oneGridCellGeometry;
    const updatedUrl = url ? url : '/assets/images/richTextWithImage.png'
    const imageUrl = `${updatedUrl}?w=${width * columns}&h=${rows * heightAccToDevice}&fit=crop&auto=format`
    return {
      srcset: imageUrl,
      src: imageUrl
    }
  }

  function handleImageClick() {
    setShowThumbnailLayout(prev => {
      return {
        currIndex: 4,
        isVisible: !prev.isVisible
      }
    })
  }

  function getHeight(index) {
    if (index === 0) {
      return 1.5 * heightAccToDevice
    }
    return isMobile ? .75 * heightAccToDevice : .5 * heightAccToDevice
  }

  const updatedImageData = useMemo(() => {
    const imageModifiedData = (imagesList?.slice(0, 5))?.map((data, index) => {
      return {
        img: data.imageUrl,
        cols: index === 0 ? columnAccToDeivce : 1,
        rows: 1,
      }
    })
    return imageModifiedData;
  }, [imagesList])


  return (
    <div className={`tw-h-full tw-w-full tw-relative ${isMobile ? 'tw-px-[16px]' : ''}`}>
      <ImageList
        sx={{ width: '100%', height: '100%' }}
        variant="quilted"
        cols={columnAccToDeivce}
        gap={gridGapAxis?.columnGap}
      >
        {updatedImageData.map((item, index) => (
          <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
            <div style={{ height: getHeight(index), width: '100%' }}
              className={"tw-relative tw-h-[100%] tw-w-[100%] tw-overflow-hidden"}>
              <img
                {...getSrcSet(item.img, item.rows, item.cols, index)}
                alt={item.title}
                loading="lazy"
                className={`tw-cursor-pointer ${!isMobile ? contentAnimationStyle[getCurrentClass(cardFrame?.hoverEffect)] : ''}`}
                style={{ height: getHeight(index), width: '100%' }}
              />
              {(index === 4 && hiddenImageLength > 0) && <div style={{borderRadius: globalBorderRadius}} onClick={handleImageClick} className="tw-absolute tw-cursor-pointer tw-bottom-0 tw-right-0 tw-h-full tw-w-full tw-flex tw-flex-col tw-justify-center tw-bg-[#00000066] ">
                <div className={`${isMobile ? 'tw-text-[20px]' : 'tw-text-[32px]'} tw-font-normal tw-text-white tw-flex tw-justify-center tw-gap-[4px]`}>
                  <div>{'+'}</div>
                  <div >{hiddenImageLength}</div>
                </div>
              </div>}
            </div>
          </ImageListItem>

        ))}
      </ImageList>
      {showThumbnailLayout.isVisible && <RenderThumnailModal cardFrame={cardFrame} config={showThumbnailLayout} setConfig={setShowThumbnailLayout} />}
    </div>
  )
}

export default GalleryCollage2;