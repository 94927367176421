import React, { useState } from "react";
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { getCurrentClass, getShapeStyles } from "../../../CardFrameCollection/utils";
import classNames from "node_modules/classnames/index";
import RenderThumnailModal from "./RenderThumnailModal";
import { COMMON_LAYOUT_TYPE } from "../Testimonials/Constants";
import { ORIGINAL_IMAGE_WIDTH, STATIC_IMAGE_PATH } from "./constant";
import { getVisibleImages } from "./helper";
import { isMobileDevice } from "src/components/WidgetMaker/WidgetDnD/isMobileDevice";

function CarouselGridLayout(props) {
  const { listPresentation, cardFrame, oneGridCellGeometry, containerTracks, viewAllItemsConfig } = props;
  const [showThumbnailLayout, setShowThumbnailLayout] = useState({
    isVisible : false,
    currIndex: 0,
  })
  const imagesData = getVisibleImages(cardFrame?.imagesData);
  const numItemsToShow = containerTracks.numColumns * containerTracks.numRows;
  const imagesList = imagesData?.slice(
    0,
    viewAllItemsConfig?.areAllItemsVisible ? imagesData?.length : numItemsToShow
  );
  const { layoutName, imageWidth } = listPresentation
  const shapeStyleCalculated = getShapeStyles(cardFrame?.imageShape, oneGridCellGeometry?.width);
  const shapeStyle = shapeStyleCalculated[0];
  const isMobile = isMobileDevice();

  const getStyleAccToLayout = () => {
    if (layoutName === COMMON_LAYOUT_TYPE.ONE || (layoutName === COMMON_LAYOUT_TYPE.TWO && imageWidth !== ORIGINAL_IMAGE_WIDTH)) {
      return { width: oneGridCellGeometry.width, height: oneGridCellGeometry.height, minHeight: 100 }
    }
    return { maxWidth: oneGridCellGeometry.width * 1.8, height: oneGridCellGeometry.height, minHeight: 100}
  }

  function handleImageClick(index) {
    setShowThumbnailLayout(prev => {
      return {
        currIndex: index,
        isVisible: !prev.isVisible
      }
    }) 
  }

  return (
    <>
    {imagesList?.map((data, index) => {
      const styleAccToLayout = getStyleAccToLayout();
      return (
        <div onClick={() => handleImageClick(index)} key={index} className="tw-w-[100%] tw-cursor-pointer">
          <div style={{...styleAccToLayout, ...shapeStyle.style}} className={classNames('tw-overflow-hidden', shapeStyle.className)}>
            <img
              src={data?.imageUrl || STATIC_IMAGE_PATH}
              style={styleAccToLayout}
              className={classNames(!isMobile ? contentAnimationStyle[getCurrentClass(cardFrame?.hoverEffect)] : '', 'tw-object-cover')}
            />
          </div>
        </div>
      )
    })}
          { showThumbnailLayout.isVisible && <RenderThumnailModal cardFrame = {cardFrame} config = {showThumbnailLayout} setConfig = {setShowThumbnailLayout} />}
      </>

  )
}

export default CarouselGridLayout;