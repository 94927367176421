import { useEffect, useState } from 'react';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { IContentTypeConditions } from '../components/types';

interface IUseSetViewAllBtnConfig {
  contentTypeConditions: IContentTypeConditions;
  isLayoutCarousel: any;
  listPresentationConfig: any;
  moreItemsAvailable: any;
  itemCountTotal: any;
  numItemsToShow: any;
  isBlogListingPage: any;
  isTestimonialListingPage: boolean
}

export function useSetViewAllBtnConfig(props: IUseSetViewAllBtnConfig) {
  const {
    contentTypeConditions,
    isLayoutCarousel,
    listPresentationConfig,
    moreItemsAvailable,
    itemCountTotal,
    numItemsToShow,
    isBlogListingPage,
    isTestimonialListingPage
  } = props;
  const {
    widgetContextState: { previewDevice },
  } = useWidgetDndContextData();

  const [viewAllItemsConfig, setViewAllItemsConfig] = useState({
    showViewAllButton: false,
    areAllItemsVisible: false,
    forCarousel: false,
  });

  const isViewAllBtnToShow = listPresentationConfig?.viewAllItemsButtonConfig?.find(
    (item) => item.isShow
  );

  useEffect(() => {
    if (isLayoutCarousel) {
      if (contentTypeConditions.featured && !contentTypeConditions.featuredReels) {
        setViewAllItemsConfig({
          areAllItemsVisible: true,
          showViewAllButton: true,
          forCarousel: false,
        });
      } else if (contentTypeConditions.testimonials && !isTestimonialListingPage) {
        setViewAllItemsConfig({
          areAllItemsVisible: false,
          showViewAllButton: true,
          forCarousel: false,
        });
      } else {
        setViewAllItemsConfig({
          areAllItemsVisible: true,
          showViewAllButton:
            isViewAllBtnToShow || contentTypeConditions.featuredCollection
              ? !!moreItemsAvailable
              : false,
          forCarousel: true,
        });
      }
    } else {
      if (contentTypeConditions.blogs && !isBlogListingPage) {
        setViewAllItemsConfig({
          areAllItemsVisible: true,
          showViewAllButton: true,
          forCarousel: false,
        });
      } else if (contentTypeConditions.logoSlider) {
        setViewAllItemsConfig({
          areAllItemsVisible: false,
          showViewAllButton: false,
          forCarousel: false,
        });
      } else if (contentTypeConditions.testimonials && !isTestimonialListingPage) {
        setViewAllItemsConfig({
          areAllItemsVisible: false,
          showViewAllButton: true,
          forCarousel: false,
        });
      } else if (isViewAllBtnToShow) {
        if (
          contentTypeConditions.featured ||
          contentTypeConditions.collection ||
          contentTypeConditions.category ||
          contentTypeConditions.gallery
        ) {
          if (numItemsToShow < itemCountTotal) {
            setViewAllItemsConfig({
              areAllItemsVisible: false,
              showViewAllButton: true,
              forCarousel: false,
            });
          } else {
            setViewAllItemsConfig({
              areAllItemsVisible: true,
              showViewAllButton: false,
              forCarousel: false,
            });
          }
        }
      } else {
        setViewAllItemsConfig({
          areAllItemsVisible: false,
          showViewAllButton: false,
          forCarousel: false,
        });
      }
    }
  }, [
    isViewAllBtnToShow,
    itemCountTotal,
    numItemsToShow,
    isLayoutCarousel,
    previewDevice,
    contentTypeConditions,
    moreItemsAvailable,
  ]);

  return { viewAllItemsConfig, setViewAllItemsConfig };
}
