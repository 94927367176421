import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import {
  BLOG_SECTION_MAX_WIDTH,
  getArrowMethods,
  getBlogData,
  getContainerTracks,
  getGridGapsForAxes,
  getOneCellGeometry,
  getRenderCardOverrides,
  getShift,
  INITIAL_SCROLL_PERCENTAGE,
  isLogoSliderAutoScroll,
  isLogoSliderFullWidth,
  isTestimonialSliderFullWidth,
  MAX_SECTION_WIDTH,
  onCarouselScroll,
} from './utils';
import { useGetIterableItems } from './hooks/useGetIterableItems';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useGetElementRects } from './hooks/useGetElementRects';
import { useProductCardElements } from '../ProductListWrapper/utils';
import dynamic from 'next/dynamic';
import { useItemClick } from 'src/hooks/useItemClick';
import eventLogger from 'src/utils/eventLogger';
import { PATH, getRoute } from 'src/utils/routes';
import { EVENT_NAMES } from 'src/constants/common';
import { useSSRSelector } from 'src/redux';
import router from 'next/router';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { RootState, useSelector } from 'src/redux/reducers';
import {
  SCROLL_TYPE,
  COMMON_LAYOUT_TYPE,
} from './components/Testimonials/Constants';
import { useSetViewAllBtnConfig } from './hooks/useSetViewAllBtnConfig';
import { IContentTypeConditions, IListPresentation } from './components/types';
import { ADMIN_WIDGETS, ADMIN_WIDGETS_NAMES } from 'src/constants/widgets';
import { useAutoScrollOrRotation } from './hooks/useAutoScrollOrRotation';
import { useLayoutTypes } from './hooks/useLayoutTypes';
import GalleryWidget from './components/GalleryWidget';

const ListDescriptionSection = dynamic(() =>
  import('./components/ListDescriptionSection').then((mod) => mod.ListDescriptionSection)
);
const FeatureProductCards = dynamic(() =>
  import('./components/FeatureProductCards').then((mod) => mod.FeatureProductCards)
);
const Reels = dynamic(() => import('./components/Reels').then((mod) => mod.Reels));
const BlogComponent = dynamic(() =>
  import('../BlogWidget').then((mod) => mod.BlogComponent)
);
const BlogHeroPage = dynamic(() =>
  import('../BlogWidget').then((mod) => mod.BlogHeroPage)
);
const LogoSliderRenderer = dynamic(() =>
  import('./components/LogoSliderComponent').then((mod) => mod.default)
);
const Testimonials = dynamic(() =>
  import('./components/Testimonials').then((mod) => mod.Testimonials)
);
const AuthorImageList = dynamic(() =>
  import('./components/Testimonials/Components/AuthorImageList').then(
    (mod) => mod.default
  )
);

const CarouselIndicator = dynamic(() =>
  import('../CardFrameCollection/components/CarouselIndicator').then((mod) => mod.default)
);
const CardFrameForCollection = dynamic(() =>
  import('../CardFrameCollection/CardFrameCollection').then((mod) => mod.default)
);
const ViewMoreButton = dynamic(() =>
  import('./components/ViewMoreButton').then((mod) => mod.default)
);
const QuickViewModal = dynamic(() =>
  import('../../QuickViewModal').then((mod) => mod.default)
);
const MultiColumnComponent = dynamic(() =>
  import('../MultiColumn').then((mod) => mod.default)
);

export default function ListPresentation(props: IListPresentation) {
  const listPresentationConfig = props?.widgetOptions?.listPresentation || {};
  const cardFrameConfig = props?.widgetOptions?.cardFrame || {};
  const contentType = listPresentationConfig?.contentItems?.type;
  const { isAutoRotateEnabled, autoRotateTimer } = useAutoScrollOrRotation({
    listPresentationConfig,
  });

  const [oldScrolType, setOldScrollType] = useState(null);

  const { isLayout4 } = useLayoutTypes({ listPresentationConfig });

  const { deviceType } = useGetDeviceType();
  const sectionContainerRef = useRef(null);
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const {
    widgetContextState: {
      globalStyle,
      previewDevice,
      activeWidgetId,
      isBuilder,
      widgets,
    },
    widgetContextHelpers: { handleWidgetPropChange },
  } = useWidgetDndContextData();

  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { blogData } = useSelector((state: RootState) => ({
    blogData:
      state?.additionalPagesReducer?.additionalPageList?.blog?.json_data?.blog?.users_list
        ?.list || props?.blogData,
  }));
  const isMobile = isBuilder ? previewDevice === 'mobile' : deviceType === 'mobile';

  const contentTypeConditions = useMemo<IContentTypeConditions>(() => {
    return {
      collection: contentType === 'collection',
      category: ['category', 'categories'].includes(contentType),
      featured: contentType?.toLocaleLowerCase()?.includes?.('feature'),
      featuredCollection: contentType?.includes?.('featuredCollection'),
      featuredReels: contentType?.includes?.('featuredReels'),
      testimonials: contentType?.includes?.('testimonials'),
      logoSlider: listPresentationConfig?.type === 'logoSlider',
      blogs: listPresentationConfig?.type === 'blog',
      multiColumn:
        listPresentationConfig?.type === 'icon' ||
        listPresentationConfig?.type === 'image',
      multiColumnForIcon: listPresentationConfig?.type === 'icon',
      multiColumnForImage: listPresentationConfig?.type === 'image',
      gallery: listPresentationConfig?.type === ADMIN_WIDGETS_NAMES.GALLERY
    };
  }, [contentType, listPresentationConfig?.type]);

  const featuredReelConfigs = {
    expandFocusedReel:
      isMobile &&
      contentTypeConditions.featuredReels &&
      cardFrameConfig?.mobileEnlargeAndFocus,
  };

  const { aspectRatio: productCardAspectRatio, isProductCardEnabled } =
    useProductCardElements();

  const carouselConfigs = globalStyle?.carousel?.config;

  const [carouselIndicatorConfigs, setCarouselIndicatorConfigs] = useState({
    scrollPercentage: INITIAL_SCROLL_PERCENTAGE,
    overflowSectionCount: 1,
    currentFocusedSectionItem: 1, // Starts from 1
    currentFocusedSectionExact: 1, // This is not ceiled, floored, or rounded (unlike currentFocusedSectionItem)
    direction: 'to-right',
  });

  const selectedListParent = listPresentationConfig?.contentItems?.listItem?.find(
    (item) => item.isVisible
  );

  const { iterableList: iterableListDefault, moreItemsAvailable } = useGetIterableItems({
    builderCollections: listPresentationConfig?.contentItems?.listItem,
    contentType,
    removeNotVisibleItems: true,
    dependency: [listPresentationConfig?.contentItems, cardFrameConfig?.testimonialsList],
  });

  const iterableList = useMemo(() => {
    if (contentTypeConditions.multiColumn)
      return cardFrameConfig?.multiColumnData?.filter((item) => item?.isVisible);
    if (contentTypeConditions.logoSlider) {
      const visibleLogo = cardFrameConfig?.multiColumnData?.filter(
        (data) => data?.isVisible
      );
      return visibleLogo;
    }
    if (contentTypeConditions.blogs) return getBlogData(blogData);
    if (contentTypeConditions.featuredReels)
      return cardFrameConfig?.reelsData?.filter((reelItem) => !!reelItem.reelUrl) || [];
    if (contentTypeConditions.testimonials)
      return (
        cardFrameConfig?.testimonialsList?.filter(
          (testimonial) => testimonial?.isVisible !== false
        ) || []
      );
    if (contentTypeConditions.gallery) {
      return (
        cardFrameConfig?.imagesData?.filter(
          (galleryData) => galleryData?.isVisible !== false
        ) || []
      );
    }
    return iterableListDefault;
  }, [iterableListDefault, contentTypeConditions, cardFrameConfig?.multiColumnData, cardFrameConfig?.imagesData]);

  const itemCountTotal = iterableList?.length;

  const isLayoutCarousel =
    listPresentationConfig?.layoutType === 'carousel' && !cardFrameConfig?.autoScroll;

  const containerVerticalPadding =
    listPresentationConfig?.[!isMobile ? 'paddingY' : 'mobilePaddingY'] || 0;
  const containerTopMargin = listPresentationConfig?.marginTop;
  const containerBottomMargin = listPresentationConfig?.marginBottom;

  // Returns number of columns & rows
  const containerTracks = getContainerTracks({
    isLayoutCarousel,
    itemCount: itemCountTotal,
    isMobile,
    listPresentationConfig,
    expandSelectedReel: featuredReelConfigs.expandFocusedReel,
    isTestimonials: contentTypeConditions.testimonials,
    // useStaticNumColumns:
    // contentTypeConditions.testimonials || contentTypeConditions?.multiColumnForImage,
  });

  // Item gaps
  const gridGapAxis = getGridGapsForAxes({
    isMobile,
    cardFrameConfig,
    listPresentationConfig,
    type: listPresentationConfig?.type,
    isLayoutTypeCard: listPresentationConfig?.appearance === 'card',
    expandSelectedReel: featuredReelConfigs.expandFocusedReel,
    numColumns: containerTracks.numColumnsOriginal,
  });
  const aspectRatio = cardFrameConfig?.aspectRatio || '1:1';
  const sectionColors =
    listPresentationConfig?.colors?.colorSchemes?.[
      listPresentationConfig?.colors?.selectedColorSchemeIndex
    ];

  // Paddings & margin calculations
  // 1
  const containerHorizontalPadding = !isMobile ? 40 : !isLayoutCarousel ? 16 : 0;
  const contentHorizontalPadding = !containerHorizontalPadding
    ? !isMobile
      ? 40
      : contentTypeConditions.testimonials
        ? 10
        : 20
    : 0;
  // 2
  let listHorizontalPadding =
    (isLayoutCarousel && isMobile && !(contentTypeConditions.gallery && listPresentationConfig.layoutName !== COMMON_LAYOUT_TYPE.TWO)) 
    ? (contentTypeConditions.testimonials ? 10 : 20) : 0;
  let listVerticalPadding = 0;
  const isCardScaleAnimation =
    (cardFrameConfig?.hoverType === 'zoomInCard' && !isMobile) ||
    (cardFrameConfig?.mobileEnlargeAndFocus && isMobile);
  if (isCardScaleAnimation) {
    listVerticalPadding += 20;
    listHorizontalPadding += 20;
  }

  // Section Width calculations
  const SECTION_WIDTH = !IS_SERVER
    ? sectionContainerRef.current?.clientWidth -
      (props?.isBlogListingPage ? 0 : containerHorizontalPadding * 2)
    : isMobile
      ? 480
      : 720;

  const showCarouselIndicators =
    isLayoutCarousel &&
    (!contentTypeConditions.testimonials ||
      (contentTypeConditions.testimonials &&
        listPresentationConfig?.layoutName !== COMMON_LAYOUT_TYPE.FOUR)) &&
    itemsContainerRef.current?.clientWidth < itemsContainerRef.current?.scrollWidth && !contentTypeConditions.testimonials;

  const showArrow = isLayoutCarousel
    ? isMobile
      ? carouselConfigs?.showArrowInMobile && showCarouselIndicators
      : showCarouselIndicators
    : false;

  const slideOffsetMultiplier = {
    forWidth:
      isMobile || (contentTypeConditions.testimonials && containerTracks.numColumns === 1)
        ? 1
        : 2,
    forGap:
      isMobile || (contentTypeConditions.testimonials && containerTracks.numColumns === 1)
        ? 1
        : 2,
  };

  const [onItemClick] = useItemClick();

  const shouldAutoScrollSlider = isLogoSliderAutoScroll({
    cardFrameConfig,
    listPresentationConfig,
  });

  const isSliderFullWidth =
    isLogoSliderFullWidth({
      cardFrameConfig,
      listPresentationConfig,
    }) ||
    isTestimonialSliderFullWidth({
      listPresentationConfig,
    });

  const showAuthorImageList =
    contentTypeConditions.testimonials && isLayout4 && !!iterableList?.length;

  const numItemsToShow = containerTracks.numColumns * containerTracks.numRows;

  const { viewAllItemsConfig, setViewAllItemsConfig } = useSetViewAllBtnConfig({
    contentTypeConditions,
    isLayoutCarousel,
    listPresentationConfig,
    moreItemsAvailable,
    itemCountTotal,
    numItemsToShow,
    isBlogListingPage: props?.isBlogListingPage,
    isTestimonialListingPage: props?.isTestimonialListingPage,
  });

  const [itemContainerLayoutChange] = useGetElementRects({
    elementRef: itemsContainerRef,
  });

  // Remaining width from section after removing gaps & x-padding
  const cardContainerWidthForEachCell =
    SECTION_WIDTH +
    gridGapAxis.columnGap -
    containerTracks.numColumns * gridGapAxis.columnGap -
    2 * listHorizontalPadding;

  // For carousel: Remove some width from columns to partially show the next one i.e.
  // reduce width slightly for each card so that an overflowed card is shown slightly to nudge the user to scroll
  const widthAdditionals = !contentTypeConditions.testimonials
    ? isLayoutCarousel && itemCountTotal > containerTracks.numColumns && isMobile
      ? -containerTracks.numColumns * 6
      : 0
    : 0;

  // Width for each column (product item)
  const oneGridCellGeometry = getOneCellGeometry({
    cardContainerWidthForEachCell,
    containerTracks,
    aspectRatio:
      !contentTypeConditions.featured || contentTypeConditions.featuredReels
        ? aspectRatio
        : productCardAspectRatio,
    widthAdditionals,
    type: listPresentationConfig?.type || '',
    isMobile,
    isLayoutTypeCard: listPresentationConfig?.appearance === 'card',
  });

  const slideOffset =
    oneGridCellGeometry.width * slideOffsetMultiplier.forWidth +
    gridGapAxis.columnGap * slideOffsetMultiplier.forGap;

  const columns = isMobile
    ? listPresentationConfig?.numColumnsMobile
    : listPresentationConfig?.numColumnsDesktop;

  const showAutoRotation =
    isLayoutCarousel &&
    isAutoRotateEnabled &&
    autoRotateTimer &&
    itemCountTotal > columns;

  const columnWidthShift = oneGridCellGeometry.width + gridGapAxis.columnGap;

  const interval = useRef(null);

  const { onNextArrowClick, onPrevArrowClick, carouselScrollTo } = getArrowMethods(
    itemsContainerRef,
    slideOffset,
    showAutoRotation,
    columnWidthShift,
    gridGapAxis.columnGap,
    stopAutoRotation,
    startAutoRotation
  );

  const activeSubwidgetIndex = widgets?.[activeWidgetId]?.props?.subWidgetIndex;
  const isSubwidgetSelecetd = props?.id === activeWidgetId && activeSubwidgetIndex !== -1;

  useEffect(() => {
    if (isSubwidgetSelecetd) {
      setOldScrollType(listPresentationConfig?.autoPlayConfig?.type);
      adjustWidgetProps();
      scrollToActiveSubwidget();
      stopAutoRotation();
    } else {
      removeAdjustedPropChange();
    }
  }, [isSubwidgetSelecetd]);

  // reset scroll position on changing layout
  useEffect(() => {
    if (itemsContainerRef.current) {
      itemsContainerRef.current.scrollTo(0, 0);
    }
  }, [listPresentationConfig.layoutName, columns, isMobile]);

  // handle auto rotation
  useEffect(() => {
    startAutoRotation();
    return () => {
      stopAutoRotation();
      contentTypeConditions.testimonials && switchToInitialState();
    };
  }, [
    itemCountTotal,
    showAutoRotation,
    autoRotateTimer,
    gridGapAxis.columnGap,
    columns,
    columnWidthShift,
    isMobile,
  ]);

  useEffect(() => {
    if (itemsContainerRef.current && iterableList?.length) {
      setTimeout(() => {
        const { clientWidth, scrollWidth } = itemsContainerRef.current || {};

        const sectionScrollWidth = Math.ceil(
          contentTypeConditions.testimonials
            ? scrollWidth / clientWidth
            : (iterableList?.length - containerTracks.numColumns) /
                slideOffsetMultiplier.forWidth +
                (!isMobile ? 1 : 0)
        );

        if (scrollWidth > clientWidth) {
          setCarouselIndicatorConfigs((data) => ({
            ...data,
            overflowSectionCount: sectionScrollWidth,
            // overflowSectionCount: Math.ceil(scrollWidth / clientWidth),
            currentFocusedSectionItem: 1,
          }));
        }
      }, 100);
    }
  }, [
    iterableList,
    isLayoutCarousel,
    isMobile,
    listPresentationConfig?.progressIndicator,
    previewDevice,
    containerTracks.numColumns,
    slideOffsetMultiplier.forWidth,
    contentTypeConditions.testimonials,
  ]);

  function adjustWidgetProps() {
    if (listPresentationConfig?.autoPlayConfig?.type) {
      listPresentationConfig.autoPlayConfig.type = SCROLL_TYPE.MANUAL;
    }
  }

  function removeAdjustedPropChange() {
    if (listPresentationConfig?.autoPlayConfig?.type && oldScrolType) {
      listPresentationConfig.autoPlayConfig.type = oldScrolType;
      setOldScrollType(null);
    }
  }

  function scrollToActiveSubwidget() {
    setTimeout(() => carouselScrollTo(activeSubwidgetIndex), 0);
  }

  function startAutoRotation() {
    if (itemsContainerRef.current && showAutoRotation) {
      const scrollContainer = itemsContainerRef.current;
      interval.current = setInterval(() => {
        const { remainingShiftForward } = getShift(
          itemsContainerRef,
          showAutoRotation,
          slideOffset,
          columnWidthShift
        );

        scrollContainer.scrollTo({
          left: scrollContainer.scrollLeft + remainingShiftForward,
          behavior: isLayout4 ? 'instant' : 'smooth',
        });

        const repeatitionStarted =
          Math.ceil(scrollContainer.scrollLeft) >=
          Math.floor(columnWidthShift * (itemCountTotal - (isLayout4 ? 0 : 1)));

        if (repeatitionStarted) {
          setTimeout(switchToInitialState, 1500);
        }
      }, autoRotateTimer * 1000);
    }
  }

  function switchToInitialState() {
    if (itemsContainerRef.current as HTMLElement) {
      itemsContainerRef.current.scrollTo({
        left: 0,
        behavior: 'instant',
      });
    }
  }

  function stopAutoRotation() {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }

  function handleHeadingClick() {
    if (
      (contentTypeConditions.multiColumn ||
        contentTypeConditions.logoSlider ||
        contentTypeConditions.testimonials ||
        contentTypeConditions.gallery
      ) &&
      isBuilder
    ) {
      handleWidgetPropChange(
        {
          subWidget: '',
          subWidgetIndex: -1,
        },
        activeWidgetId
      );
    }
  }

  const onCollectionClick = (type, data, e, openInNewTab) => {
    e.stopPropagation();
    switch (type) {
      case 'product': {
        onItemClick(data, e, openInNewTab);
        break;
      }
      case 'collection': {
        eventLogger(
          EVENT_NAMES.COLLECTION_WIDGET_CARD_CLICK,
          { collectionId: data.id },
          true
        );
        const link = getRoute(PATH.collectionCategories(data.id), storeData?.domain);
        !openInNewTab ? router.push(link) : window.open(link, '_blank');
        break;
      }
      case 'category': {
        const redirectUrl = getRoute(
          `${
            isPLPRequiredSystem(storeData?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
          }?cid=${data?.id || 0}`,
          storeData?.store_info?.domain
        );
        if (openInNewTab) {
          window.open(redirectUrl, '_blank');
        } else {
          router.push(redirectUrl, undefined, { shallow: true });
        }
        break;
      }
    }
  };

  const _RenderViewMoreButton = (isVisble = true) => {
    return (
      <ViewMoreButton
        isVisible={viewAllItemsConfig.showViewAllButton && isVisble}
        listPresentationConfig={listPresentationConfig}
        viewAllItemsConfig={viewAllItemsConfig}
        setViewAllItemsConfig={setViewAllItemsConfig}
        cardFrameConfig={cardFrameConfig}
        selectedListParent={selectedListParent}
        onCollectionClick={onCollectionClick}
      />
    );
  };

  function _RenderCards() {
    if (contentTypeConditions.testimonials) {
      return (
        <Testimonials
          cardFrameConfig={cardFrameConfig}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          isBuilder={isBuilder}
          isMobile={isMobile}
          itemCountTotal={itemCountTotal}
          gridGapAxis={gridGapAxis}
          sectionContainerRef={sectionContainerRef}
          carouselIndicatorConfigs={carouselIndicatorConfigs}
          containerTracks={containerTracks}
          isTestimonialListingPage={props?.isTestimonialListingPage}
        />
      );
    }
    if (contentTypeConditions.featuredReels) {
      return (
        <Reels
          cardFrameConfig={cardFrameConfig}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          isBuilder={isBuilder}
          isMobile={isMobile}
          featuredReelConfigs={featuredReelConfigs}
          carouselIndicatorConfigs={carouselIndicatorConfigs}
        />
      );
    }
    if (contentTypeConditions.logoSlider) {
      return (
        <LogoSliderRenderer
          cardFrameConfig={cardFrameConfig}
          listPresentationConfig={listPresentationConfig}
          gridGapAxis={gridGapAxis}
          sectionContainerRef={sectionContainerRef}
          oneGridCellGeometry={oneGridCellGeometry}
          isMobile={isMobile}
          sectionColors={sectionColors}
          itemCountTotal={itemCountTotal}
          isSliderFullWidth={isSliderFullWidth}
        />
      );
    }
    if (contentTypeConditions.blogs) {
      // on blog listing page first blog will render using blog hero banner so removing first blog from list on listing page.
      const simplifiedBlogData = getBlogData(blogData);
      const blogDataLength = simplifiedBlogData?.length;
      const updatedBlogData = props?.isBlogListingPage
        ? simplifiedBlogData?.slice(1, blogDataLength)
        : simplifiedBlogData;
      return updatedBlogData?.map((columnData, index) => (
        <BlogComponent
          key={index}
          index={index}
          columnData={columnData}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          cardFrameConfig={cardFrameConfig}
          sectionColors={sectionColors}
        />
      ));
    }
    if(contentTypeConditions.gallery) {
      return (
        <GalleryWidget 
         listPresentation = {listPresentationConfig}
         cardFrame = {cardFrameConfig}
         oneGridCellGeometry={oneGridCellGeometry}
         containerTracks = {containerTracks}
         gridGapAxis = {gridGapAxis}
         viewAllItemsConfig = {viewAllItemsConfig}
        />
      )
    }
    if (!contentTypeConditions.multiColumn) {
      const listToRender = iterableList?.slice(
        0,
        viewAllItemsConfig?.areAllItemsVisible ? itemCountTotal : numItemsToShow
      );
      if (!listToRender?.length) {
        return null;
      }
      return listToRender.map((data, index) => {
        if (
          [
            'featuredCollection',
            'featuredTag',
            ADMIN_WIDGETS.FEATURED_CATEGORIES.type,
          ].includes(contentType)
        ) {
          const textColorOverride = getRenderCardOverrides({
            listPresentationConfig,
            sectionColors,
          });
          return (
            <FeatureProductCards
              key={index}
              isProductCardEnabled={isProductCardEnabled}
              data={data}
              index={index}
              oneGridCellGeometry={oneGridCellGeometry}
              isMobile={isMobile}
              sectionColorsOverrides={{ textColor: textColorOverride }}
              customClass={'!tw-h-auto !tw-w-min'}
            />
          );
        }
        return (
          <>
            <CardFrameForCollection
              key={index}
              itemConfig={data}
              itemIndex={index}
              containerTracks={containerTracks}
              cardFrameConfig={cardFrameConfig}
              oneGridCellGeometry={oneGridCellGeometry}
              openInNewTab={listPresentationConfig?.openInNewTab}
              isMobile={isMobile}
              onCollectionClick={onCollectionClick}
            />
          </>
        );
      });
    }
    // for multi-column
    if (!iterableList?.length) {
      return null;
    }
    return iterableList?.map((columnData, index) =>
      columnData?.isVisible ? (
        <MultiColumnComponent
          key={index}
          index={index}
          columnData={columnData}
          oneGridCellGeometry={oneGridCellGeometry}
          listPresentationConfig={listPresentationConfig}
          cardFrameConfig={cardFrameConfig}
          sectionColors={sectionColors}
        />
      ) : null
    );
  }

  function getSectionStyles(): React.CSSProperties {
    return {
      padding: (props?.isBlogListingPage)
        ? 0
        : `${containerVerticalPadding}px ${isSliderFullWidth ? 0 : containerHorizontalPadding}px`,
      margin: `${containerTopMargin}px ${isSliderFullWidth ? '' : 'auto'} ${containerBottomMargin}px ${isSliderFullWidth ? '' : 'auto'}`,
      maxWidth: isSliderFullWidth
        ? '100vw'
        : `${props?.isBlogListingPage ? BLOG_SECTION_MAX_WIDTH : MAX_SECTION_WIDTH}px`,
      ...(props.overrides?.layout?.styles || {}),
    };
  }

  function getCardContainerClass(): string {
    return classNames(
      `no-scrollbar tw-max-w-[100%] tw-overscroll-x-none tw-flex-shrink-0`,
      cardFrameConfig?.contentAlignment !== 'left' && !props?.isBlogListingPage
        ? !shouldAutoScrollSlider
          ? (contentTypeConditions.gallery &&  listPresentationConfig.layoutName === COMMON_LAYOUT_TYPE.THREE) ? 'tw-w-[100%]' : 'tw-mx-auto'
          : ''
        : '',
      !isLayoutCarousel ? `tw-grid` : `tw-flex tw-items-stretch`,
      listPresentationConfig?.layoutType === 'carousel' &&
        contentTypeConditions.logoSlider &&
        !shouldAutoScrollSlider
        ? `!tw-pb-[15px] !tw-pt-[35px] ${isMobile ? '!tw-px-[16px]' : ''}`
        : ''
    );
  }

  function getCardContainerStyles(): React.CSSProperties {
    const style: React.CSSProperties = {};
    if (!isLayoutCarousel) {
      style.gridTemplateColumns = `repeat(${viewAllItemsConfig?.areAllItemsVisible ? 'auto-fit' : containerTracks?.numColumnsMax}, ${oneGridCellGeometry.width}px)`;
      if (!viewAllItemsConfig?.areAllItemsVisible) {
        style.gridTemplateRows = `repeat(${containerTracks?.numRows}, auto)`;
      }
      for (const key in gridGapAxis) {
        if (key) {
          style[key] = gridGapAxis[key];
        }
      }
    } else {
      style.overflowX = 'auto';
      style.gap = gridGapAxis.columnGap;
    }
    style.overflowY =
      contentTypeConditions.featured && !contentTypeConditions.featuredReels
        ? 'hidden'
        : 'visible';
    style.padding = `${listVerticalPadding}px ${listHorizontalPadding}px`;
    if (isCardScaleAnimation) {
      style.marginBottom = '-20px';
      style.marginTop = '-20px';
    }
    if (featuredReelConfigs.expandFocusedReel) {
      style.paddingLeft = `calc(50% - ${oneGridCellGeometry.width / 2}px)`;
      style.paddingRight = `calc(50% - ${oneGridCellGeometry.width / 2}px)`;
      style.scrollSnapType = 'x mandatory';
    }
    return style;
  }

  if (props.isVisible === false) {
    return null;
  }

  return (
    <div
      className={classNames(
        'listPresentationContainer',
        props?.isTestimonialListingPage ? 'tw-mx-auto tw-max-w-[1200px]' : ''
      )}
      style={{
        background:
          typeof props.overrides?.layout?.styles?.background === 'string'
            ? props.overrides?.layout?.styles?.background
            : sectionColors?.background,
      }}
    >
      <section
        ref={sectionContainerRef}
        className={classNames(
          `${cardFrameConfig?.contentAlignment !== 'left' ? (!shouldAutoScrollSlider ? 'tw-items-center' : '') : ''} tw-flex tw-w-[100%] tw-flex-col`
        )}
        style={getSectionStyles()}
        onClick={handleHeadingClick}
      >
        <ListDescriptionSection
          listPresentationConfig={listPresentationConfig}
          isMobile={isMobile}
          containerHorizontalPadding={contentHorizontalPadding}
          showArrow={showArrow}
          sectionColors={sectionColors}
          carouselIndicatorConfigs={carouselIndicatorConfigs}
          onNextArrowClick={onNextArrowClick}
          onPrevArrowClick={onPrevArrowClick}
          isLogoSliderCarousel={
            listPresentationConfig?.layoutType === 'carousel' &&
            contentTypeConditions.logoSlider
          }
          isFocusedReelExpandOnMobile={featuredReelConfigs.expandFocusedReel}
          carouselConfigs={carouselConfigs}
        />
        {props?.isBlogListingPage && (
          <BlogHeroPage
            blogData={getBlogData(blogData)}
            listPresentationConfig={listPresentationConfig}
            cardFrameConfig={cardFrameConfig}
          />
        )}
        {/** This renders card columns either as carousel or grid */}
        <div
          className={getCardContainerClass()}
          style={getCardContainerStyles()}
          ref={itemsContainerRef}
          onScroll={(e) =>
            onCarouselScroll({
              e,
              setCarouselIndicatorConfigs,
              slideOffset,
              widthOffset: widthAdditionals,
            })
          }
        >
          {_RenderCards()}
          {_RenderViewMoreButton(viewAllItemsConfig.forCarousel)}
        </div>
        {contentTypeConditions.testimonials && showAuthorImageList && (
          <AuthorImageList
            listPresentationConfig={listPresentationConfig}
            carouselIndicatorConfigs={carouselIndicatorConfigs}
            isMobile={isMobile}
            carouselScrollTo={carouselScrollTo}
            totalItems={itemCountTotal}
            testimonialsData={iterableList}
            imageShape={cardFrameConfig?.imageShape}
          />
        )}
        <CarouselIndicator
          isVisible={showCarouselIndicators}
          showArrowUI={showArrow}
          config={carouselConfigs}
          itemContainerLayoutChange={itemContainerLayoutChange}
          carouselIndicatorConfigs={carouselIndicatorConfigs}
          onPrevArrowClick={() => onPrevArrowClick()}
          onNextArrowClick={() => onNextArrowClick()}
          arrowPosition={carouselConfigs?.arrowPosition}
          isMobile={isMobile}
          containerHorizontalPadding={contentHorizontalPadding}
        />
        {_RenderViewMoreButton(!viewAllItemsConfig.forCarousel)}
        <QuickViewModal />
      </section>
    </div>
  );
}
